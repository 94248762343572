<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--内容-->
      <div class="content">
        <div class="edit_content">
          <!--表单-->
          <el-form :model="edit_from" label-position="left">
            <el-collapse v-model="select_name">
              <!--球员图片-->
              <!--封面信息 CoverInfo-->
              <el-collapse-item name="1">
                <template #title>
                  <div class="title">封面信息 Cover Info</div>
                </template>
                <el-form-item label="封面球员图片" label-width="500px">
                  <el-upload
                      class="avatar-uploader"
                      :on-change="before_cover_image_upload"
                      accept="image/jpg,image/jpeg,image/png"
                      :show-file-list="false"
                      :auto-upload="false"
                  >
                    <img alt="" v-if="cover_image_data" :src="cover_image_data" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="中文名 Chinese name" label-width="500px">
                  <el-input type="text" v-model="edit_from.cover_info.chinese_name"/>
                </el-form-item>
                <el-form-item label="英文名 English name" label-width="500px">
                  <el-input type="text" v-model="edit_from.cover_info.english_name"/>
                </el-form-item>
                <el-form-item label="出生日期 Date Of Birth" label-width="500px">
                  <el-config-provider :locale="locale">
                    <el-date-picker v-model="edit_from.cover_info.date_of_birth" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY"/>
                  </el-config-provider>
                </el-form-item>
                <el-form-item label="场上位置 Field Position" label-width="500px">
                  <el-input type="text" v-model="edit_from.cover_info.field_position"/>
                </el-form-item>
                <el-form-item label="巅峰能力 Peak Ability" label-width="500px">
                  <el-input type="text" v-model="edit_from.cover_info.peak_ability"/>
                </el-form-item>
                <el-form-item label="代表国家 Represent The Country" label-width="500px">
                  <el-input type="text" v-model="edit_from.cover_info.represent_the_country"/>
                </el-form-item>
              </el-collapse-item>
              <!--细节 Details-->
              <el-collapse-item name="2">
                <template #title>
                  <div class="title">细节 Details</div>
                </template>
                <el-form-item label="细节球员图片" label-width="500px">
                  <el-upload
                      class="avatar-uploader"
                      :on-change="before_details_image_upload"
                      accept="image/jpg,image/jpeg,image/png"
                      :show-file-list="false"
                      :auto-upload="false"
                  >
                    <img alt="" v-if="details_image_data" :src="details_image_data" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="名 First Name" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.first_name"/>
                </el-form-item>
                <el-form-item label="姓 Second Name" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.second_name"/>
                </el-form-item>
                <el-form-item label="常用名 Common Name" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.common_name"/>
                </el-form-item>
                <el-form-item label="全名 Full Name" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.full_name"/>
                </el-form-item>
                <el-form-item label="种族 Ethnicity" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.ethnicity"/>
                </el-form-item>
                <el-form-item label="发色 Hair Colour" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.hair_colour"/>
                </el-form-item>
                <el-form-item label="头发长度 Hair Length" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.hair_length"/>
                </el-form-item>
                <el-form-item label="肤色 Skin Colour" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.skin_colour"/>
                </el-form-item>
                <el-form-item label="生日 Date Of Birth" label-width="500px">
                  <el-config-provider :locale="locale">
                    <el-date-picker v-model="this.edit_from.details.date_of_birth" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY" />
                  </el-config-provider>
                </el-form-item>
                <el-form-item label="出生城市 City Of Birth" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.city_of_birth"/>
                </el-form-item>
                <el-form-item label="国家/地区 Nation" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.nation"/>
                </el-form-item>
                <el-form-item label="第二国籍/地区籍 Cecond Nations" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.cecond_nations"/>
                </el-form-item>
                <el-form-item label="青年队时宣布效力国家/地区 Nation Declared For At Youth Level" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.details.nation_declared_for_at_youth_level"/>
                </el-form-item>
                <el-form-item label="国家/地区队退役时间 International Retirement Date" label-width="500px">
                  <el-config-provider :locale="locale">
                    <el-date-picker v-model="this.edit_from.details.international_retirement_date" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY"/>
                  </el-config-provider>
                </el-form-item>
                <el-form-item label="简介 Synopsis" label-width="500px">
                  <el-input type="textarea" :rows="5" v-model="this.edit_from.details.synopsis"/>
                </el-form-item>
              </el-collapse-item>
              <!--国际赛事资料 International Data-->
              <el-collapse-item name="3">
                <template #title>
                  <div class="title">国际赛事资料 International Data</div>
                </template>
                <el-form-item label="国家/地区队比赛上场次数 International Apps" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.international_apps"/>
                </el-form-item>
                <el-form-item label="国家/地区队比赛进球数 International Goals" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.international_goals"/>
                </el-form-item>
                <el-form-item label="21岁以下国家/地区队比赛上场次数 U21International Apps" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.u21_international_apps"/>
                </el-form-item>
                <el-form-item label="21岁以下国家/地区队比赛进球数 U21 International Goals" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.u21_international_goals"/>
                </el-form-item>
                <el-form-item label="国际比赛处子秀日期 International Debut Date" label-width="500px">
                  <el-config-provider :locale="locale">
                    <el-date-picker v-model="this.edit_from.international_data.international_debut_date" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY" />
                  </el-config-provider>
                </el-form-item>
                <el-form-item label="国家/地区队处子秀对手 International Debut Against" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.international_debut_against"/>
                </el-form-item>
                <el-form-item label="国家/地区队处子球日期 Date Of First International Goal" label-width="500px">
                  <el-config-provider :locale="locale">
                    <el-date-picker v-model="this.edit_from.international_data.date_of_first_international_goal" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY" />
                  </el-config-provider>
                </el-form-item>
                <el-form-item label="首粒国际比赛进球对手 First International Goal Againt" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.first_international_goal_againt"/>
                </el-form-item>
                <el-form-item label="其他国家/地区队上场次数 Other International Apps" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.other_international_apps"/>
                </el-form-item>
                <el-form-item label="其他国家/地区队比赛进球 Other International Goals" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.other_international_goals"/>
                </el-form-item>
                <el-form-item label="其他青年队国家/地区队比赛上场次数 U21 Other International Apps" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.u21_other_international_apps"/>
                </el-form-item>
                <el-form-item label="其他青年队国家/地区队比赛进球数 U21 Other International Goals" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.international_data.u21_other_international_goals"/>
                </el-form-item>
              </el-collapse-item>
              <!--合同 Contract-->
              <el-collapse-item name="4">
                <template #title>
                  <div class="title">合同 Contract</div>
                </template>
                <el-form-item label="喜爱号码 Preferred Squad Number" label-width="500px">
                  <el-input type="text" v-model="edit_from.contract.preferred_squad_number"/>
                </el-form-item>
              </el-collapse-item>
              <!--属性 Attributes-->
              <el-collapse-item name="5">
                <template #title>
                  <div class="title">属性 Attributes</div>
                </template>
                <el-form-item label="适应性 Adaptability" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.adaptability"/>
                </el-form-item>
                <el-form-item label="雄心 Ambition" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.ambition"/>
                </el-form-item>
                <el-form-item label="争论 Controversy" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.controversy"/>
                </el-form-item>
                <el-form-item label="忠诚 Loyalty" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.loyalty"/>
                </el-form-item>
                <el-form-item label="抗压能力 Pressure" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.pressure"/>
                </el-form-item>
                <el-form-item label="职业 Professionalism" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.professionalism"/>
                </el-form-item>
                <el-form-item label="体育道德 Sportsmanship" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.sportsmanship"/>
                </el-form-item>
                <el-form-item label="情绪控制 Temperament" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.temperament"/>
                </el-form-item>
                <el-form-item label="语言 Language" label-width="500px">
                  <el-input type="text" v-model="edit_from.attributes.language"/>
                </el-form-item>
              </el-collapse-item>
              <!--综合 General-->
              <el-collapse-item name="6">
                <template #title>
                  <div class="title">综合 General</div>
                </template>
                <el-form-item label="能力 Ability" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.ability" />
                </el-form-item>
                <el-form-item label="身高 Height" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.height" />
                </el-form-item>
                <el-form-item label="体重 Weight" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.weight" />
                </el-form-item>
                <el-form-item label="左脚 Left Foot" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.left_foot" />
                </el-form-item>
                <el-form-item label="右脚 Right Foot" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.right_foot" />
                </el-form-item>
                <el-form-item label="国内培养 Trained In Nation" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.trained_in_nation" />
                </el-form-item>
                <el-form-item label="俱乐部培养 Trained At Club" label-width="500px">
                  <el-input type="text" v-model="edit_from.general.trained_at_club" />
                </el-form-item>
              </el-collapse-item>
              <!--位置 Positions-->
              <el-collapse-item name="7">
                <template #title>
                  <div class="title">位置 Positions</div>
                </template>
                <el-form-item label="门将 Goalkeeper" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.goalkeeper"/>
                </el-form-item>
                <el-form-item label="清道夫 Sweeper" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.sweeper"/>
                </el-form-item>
                <el-form-item label="左后卫 Defender Left" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.defender_left"/>
                </el-form-item>
                <el-form-item label="中后卫 Defender Central" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.defender_central"/>
                </el-form-item>
                <el-form-item label="右后卫 Defender Right" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.defender_right"/>
                </el-form-item>
                <el-form-item label="防守型中场 Defensive Midfielder" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.defensive_midfielder"/>
                </el-form-item>
                <el-form-item label="左边卫 Wing Back Left" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.wing_back_left"/>
                </el-form-item>
                <el-form-item label="右边卫 Wing Back Right" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.wing_back_right"/>
                </el-form-item>
                <el-form-item label="左前卫 Midfielder Left" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.midfielder_left"/>
                </el-form-item>
                <el-form-item label="中前卫 Midfielder Central" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.midfielder_central"/>
                </el-form-item>
                <el-form-item label="右前卫 Midfielder Right" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.midfielder_right"/>
                </el-form-item>
                <el-form-item label="左路攻击前卫 Attacking Midfielder Left" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.attacking_midfielder_left"/>
                </el-form-item>
                <el-form-item label="中路攻击前卫 Attacking Midfielder Central" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.attacking_midfielder_central"/>
                </el-form-item>
                <el-form-item label="右路攻击前卫 Attacking Midfielder Right" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.attacking_midfielder_right"/>
                </el-form-item>
                <el-form-item label="前锋 Attacker" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.attacker"/>
                </el-form-item>
                <el-form-item label="主打位置 Preferred Central Position" label-width="500px">
                  <el-input type="text" v-model="edit_from.positions.preferred_central_position"/>
                </el-form-item>
              </el-collapse-item>
              <!--精神属性 Mental Attributes-->
              <el-collapse-item name="8">
                <template #title>
                  <div class="title">精神属性 Mental Attributes</div>
                </template>
                <el-form-item label="侵略性 Aggression" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.aggression"/>
                </el-form-item>
                <el-form-item label="预判 Anticipation" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.anticipation"/>
                </el-form-item>
                <el-form-item label="勇敢 Bravery" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.bravery"/>
                </el-form-item>
                <el-form-item label="镇定 Composure" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.composure"/>
                </el-form-item>
                <el-form-item label="集中 Concentration" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.concentration"/>
                </el-form-item>
                <el-form-item label="稳定 Consistency" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.consistency"/>
                </el-form-item>
                <el-form-item label="决断 Decisions" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.decisions"/>
                </el-form-item>
                <el-form-item label="意志力 Determination" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.determination"/>
                </el-form-item>
                <el-form-item label="坑脏动作 Dirtyness" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.dirtyness"/>
                </el-form-item>
                <el-form-item label="想象力 Flair" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.flair"/>
                </el-form-item>
                <el-form-item label="大赛发挥 Important Matches" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.important_matches"/>
                </el-form-item>
                <el-form-item label="影响力 Influence" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.influence"/>
                </el-form-item>
                <el-form-item label="无球跑动 Off The Ball" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.off_the_ball"/>
                </el-form-item>
                <el-form-item label="防守站位 Positioning" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.positioning"/>
                </el-form-item>
                <el-form-item label="团队合作 Team Work" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.team_work"/>
                </el-form-item>
                <el-form-item label="视野 Cretivity" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.cretivity"/>
                </el-form-item>
                <el-form-item label="工作投入 Work Rate" label-width="500px">
                  <el-input type="text" v-model="edit_from.mental_attributes.work_rate"/>
                </el-form-item>
              </el-collapse-item>
              <!--身体属性 Physical Attributes-->
              <el-collapse-item name="9">
                <template #title>
                  <div class="title">身体属性 Physical Attributes</div>
                </template>
                <el-form-item label="爆发力 Acceleration" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.acceleration" />
                </el-form-item>
                <el-form-item label="灵活 Agility" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.agility" />
                </el-form-item>
                <el-form-item label="平衡 Balance" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.balance" />
                </el-form-item>
                <el-form-item label="受伤倾向 Injury Proneness" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.injury_proneness" />
                </el-form-item>
                <el-form-item label="弹跳 Jumping" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.jumping" />
                </el-form-item>
                <el-form-item label="体质 Natural Fitness" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.natural_fitness" />
                </el-form-item>
                <el-form-item label="速度 Pace" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.pace" />
                </el-form-item>
                <el-form-item label="耐力 Stamina" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.stamina" />
                </el-form-item>
                <el-form-item label="强壮 Strength" label-width="500px">
                  <el-input type="text" v-model="edit_from.physical_attributes.strength" />
                </el-form-item>
              </el-collapse-item>
              <!--技术属性 Technical Attributes-->
              <el-collapse-item name="10">
                <template #title>
                  <div class="title">技术属性 Technical Attributes</div>
                </template>
                <el-form-item label="角球 Corners" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.corners" />
                </el-form-item>
                <el-form-item label="传中 Crossing" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.crossing" />
                </el-form-item>
                <el-form-item label="盘带 Dribbling" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.dribbling" />
                </el-form-item>
                <el-form-item label="射门 Finishing" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.finishing" />
                </el-form-item>
                <el-form-item label="接球 First Touch" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.first_touch" />
                </el-form-item>
                <el-form-item label="任意球 Free Kicks" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.free_kicks" />
                </el-form-item>
                <el-form-item label="头球 Headings" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.headings" />
                </el-form-item>
                <el-form-item label="远射 Long Shots" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.long_shots" />
                </el-form-item>
                <el-form-item label="界外球 Long Throws" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.long_throws" />
                </el-form-item>
                <el-form-item label="盯人 Marking" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.marking" />
                </el-form-item>
                <el-form-item label="传球 Passing" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.passing" />
                </el-form-item>
                <el-form-item label="罚点球 Penalty Taking" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.penalty_taking" />
                </el-form-item>
                <el-form-item label="抢断 Tackling" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.tackling" />
                </el-form-item>
                <el-form-item label="技术 Technique" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.technique" />
                </el-form-item>
                <el-form-item label="多面性 Versatility" label-width="500px">
                  <el-input type="text" v-model="edit_from.technical_attributes.versatility" />
                </el-form-item>
              </el-collapse-item>
              <!--门将属性 Goalkeeping Attributes-->
              <el-collapse-item name="11">
                <template #title>
                  <div class="title">门将属性 Goalkeeping Attributes</div>
                </template>
                <el-form-item label="制空能力 Aerial Ability" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.aerial_ability"/>
                </el-form-item>
                <el-form-item label="拦截传中 Command Of Area" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.command_of_area"/>
                </el-form-item>
                <el-form-item label="指挥防守 Communication" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.communication"/>
                </el-form-item>
                <el-form-item label="神经指数 Eccentricity" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.eccentricity"/>
                </el-form-item>
                <el-form-item label="手控球 Handing" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.handing"/>
                </el-form-item>
                <el-form-item label="大脚开球 Kicking" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.kicking"/>
                </el-form-item>
                <el-form-item label="一对一 One On Ones" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.one_on_ones"/>
                </el-form-item>
                <el-form-item label="反应 Reflexes" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.reflexes"/>
                </el-form-item>
                <el-form-item label="出击 Rushing Out" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.rushing_out"/>
                </el-form-item>
                <el-form-item label="击球倾向 Tendency To Punch" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.tendency_to_punch"/>
                </el-form-item>
                <el-form-item label="手抛球能力 Throwing" label-width="500px">
                  <el-input type="text" v-model="edit_from.goalkeeping_attributes.throwing"/>
                </el-form-item>
              </el-collapse-item>
              <!--习惯表现 Perferred Moves-->
              <el-collapse-item name="12">
                <template #title>
                  <div class="title">习惯表现 Perferred Moves</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.perferred_moves_data">
                  <el-table-column prop="text" label="习惯描述" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.text"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_perferred_moves_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_perferred_moves_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--喜欢的人 Favourite People-->
              <el-collapse-item name="13">
                <template #title>
                  <div class="title">喜欢的人 Favourite People</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.favourite_people_data">
                  <el-table-column prop="personnel" label="人员" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.personnel"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="level" label="级别" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.level"/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="reason" label="原因" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.reason"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="continued" label="持续" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.continued"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_favourite_people_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_favourite_people_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--厌恶的人 Disliked People-->
              <el-collapse-item name="14">
                <template #title>
                  <div class="title">厌恶的人 Disliked People</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.disliked_people_data">
                  <el-table-column prop="personnel" label="人员" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.personnel"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="level" label="级别" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.level"/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="continued" label="持续" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.continued"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_disliked_people_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_disliked_people_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--钟爱的俱乐部 Favourite Clubs-->
              <el-collapse-item name="15">
                <template #title>
                  <div class="title">钟爱的俱乐部 Favourite Clubs</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.favourite_clubs_data">
                  <el-table-column prop="club" label="俱乐部" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.club"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="reason" label="原因" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.reason"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="level" label="级别" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.level"/>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_favourite_clubs_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_favourite_clubs_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--讨厌的俱乐部 Disliked Clubs-->
              <el-collapse-item name="16">
                <template #title>
                  <div class="title">讨厌的俱乐部 Disliked Clubs</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.disliked_clubs_data">
                  <el-table-column prop="club" label="俱乐部" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.club"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="level" label="级别" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.level"/>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_disliked_clubs_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_disliked_clubs_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--国家队生涯 National Team Career-->
              <el-collapse-item name="17">
                <template #title>
                  <div class="title">国家队生涯 National Team Career</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.national_team_career_data">
                  <el-table-column prop="match_date" label="比赛日期" align="center">
                    <template #default="scope">
                      <el-config-provider :locale="locale">
                        <el-date-picker v-model="scope.row.match_date" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY" />
                      </el-config-provider>
                    </template>
                  </el-table-column>
                  <el-table-column prop="opponent" label="比赛对手" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.opponent"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="goal" label="进球" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.goal" model-value="0"/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="assist" label="助攻" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.assist" model-value="0"/>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_national_team_career_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_national_team_career_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--转会记录 Transfer History-->
              <el-collapse-item name="18">
                <template #title>
                  <div class="title">转会记录 Transfer History</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.transfer_history_data">
                  <el-table-column prop="transfer_date" label="转会日期" align="center">
                    <template #default="scope">
                      <el-config-provider :locale="locale">
                        <el-date-picker v-model="scope.row.transfer_date" type="date" placeholder="请选择日期" format="DD/MM/YYYY" value-format="DD/MM/YYYY"/>
                      </el-config-provider>
                    </template>
                  </el-table-column>
                  <el-table-column prop="transfer_to_the_team" label="转入球队" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.transfer_to_the_team"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="transfer_fee" label="转会费" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.transfer_fee"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_transfer_history_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_transfer_history_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--球员历史 Playing History-->
              <el-collapse-item name="19">
                <template #title>
                  <div class="title">球员历史 Playing History</div>
                </template>
                <el-table stripe style="width: 100%" :data="edit_from.playing_history_data">
                  <el-table-column prop="year" label="年" align="center">
                    <template #default="scope">
                      <el-config-provider :locale="locale">
                        <el-date-picker v-model="scope.row.year" type="year" placeholder="请选择年份" format="YYYY" value-format="YYYY"/>
                      </el-config-provider>
                    </template>
                  </el-table-column>
                  <el-table-column prop="club" label="俱乐部" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.club"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="attributes" label="属性" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.attributes"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="debut" label="登场" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.debut" model-value="0"/>
                    </template>
                  </el-table-column>
                  <el-table-column prop="goal" label="进球" align="center">
                    <template #default="scope">
                      <el-input v-model="scope.row.goal" model-value="0"/>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="100px">
                    <template #header>
                      <el-button type="primary" size="small" @click="add_playing_history_data">新增</el-button>
                    </template>
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="del_playing_history_data(scope.$index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <!--游戏版本 Games Version-->
              <el-collapse-item name="20">
                <template #title>
                  <div class="title">游戏版本 Games Version</div>
                </template>
                <el-form-item label="游戏版本 Games Version" label-width="500px">
                  <el-input type="text" v-model="this.edit_from.game_version"/>
                </el-form-item>
              </el-collapse-item>
              <!--积分-->
              <el-collapse-item name="21">
                <template #title>
                  <div class="title">积分 Integral</div>
                </template>
                <el-form-item label="积分 Integral" label-width="500px">
                  <el-input-number v-model="this.edit_from.integral" :min="0" :max="999999999" />
                </el-form-item>
              </el-collapse-item>
              <br />
              <br />
              <br />
              <el-form-item label="" label-width="400px">
                <el-button size="large" type="primary" @click="post_sportsman_check">提交</el-button>
                <el-button size="large" type="primary" @click="jump_page('/')">返回首页</el-button>
              </el-form-item>
            </el-collapse>
          </el-form>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Plus } from '@element-plus/icons-vue';
import {success_hint, error_hint, warning_hint, jump_page} from "../../tool/tools";
import {add_sportsman, image_upload} from "../../api/api";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  name: "SportsmanDetailRelease",
  data(){
    return {
      locale: zhCn,
      select_name: "1",
      cover_image_data: null,
      details_image_data: null,
      edit_from: {
        // 封面信息
        cover_info: {
          // 图片
          image: "",
          // 中文名
          chinese_name: "",
          // 英文名
          english_name: "",
          // 出生日期
          date_of_birth: "",
          // 场上位置
          field_position: "",
          // 巅峰能力
          peak_ability: "",
          // 代表国家
          represent_the_country: ""
        },
        // 细节
        details: {
          // 图片
          image: "",
          // 名
          first_name: "",
          // 姓
          second_name: "",
          // 常用名
          common_name: "",
          // 全名
          full_name: "",
          // 种族
          ethnicity: "",
          // 发
          hair_colour: "",
          // 头发长度
          hair_length: "",
          // 肤色
          skin_colour: "",
          // 生日
          date_of_birth: "",
          // 出生城市
          city_of_birth: "",
          // 国家/地区
          nation: "",
          // 第二国籍/地区籍
          cecond_nations: "",
          // 青年队时宣布效力国家/地区
          nation_declared_for_at_youth_level: "",
          // 国家/地区队退役时间
          international_retirement_date: "",
          // 简介
          synopsis: ""
        },
        // 国际赛事资料
        international_data: {
          // 国家/地区队比赛上场次数
          international_apps: "",
          // 国家/地区队比赛进球数
          international_goals: "",
          // 21岁以下国家/地区队比赛上场次数
          u21_international_apps: "",
          // 21岁以下国家/地区队比赛进球数
          u21_international_goals: "",
          // 国际比赛处子秀日期
          international_debut_date: "",
          // 国家/地区队处子秀对手
          international_debut_against: "",
          // 国家/地区队处子球日期
          date_of_first_international_goal: "",
          // 首粒国际比赛进球对手
          first_international_goal_againt: "",
          // 其他国家/地区队上场次数
          other_international_apps: "",
          // 其他国家/地区队比赛进球
          other_international_goals: "",
          // 其他青年队国家/地区队比赛上场次数
          u21_other_international_apps: "",
          // 其他青年队国家/地区队比赛进球数
          u21_other_international_goals: ""
        },
        // 合同 Contract
        contract: {
          preferred_squad_number: ""
        },
        // 属性
        attributes: {
          // 适应性
          adaptability: "",
          // 雄心
          ambition: "",
          // 争论
          controversy: "",
          // 忠诚
          loyalty: "",
          // 抗压能力
          pressure: "",
          // 职业
          professionalism: "",
          // 体育道德
          sportsmanship: "",
          // 情绪控制
          temperament: "",
          // 语言
          language: ""
        },
        // 综合
        general: {
          // 能力
          ability: "",
          // 身高
          height: "",
          // 体重
          weight: "",
          // 左脚
          left_foot: "",
          // 右脚
          right_foot: "",
          // 国内培养
          trained_in_nation: "",
          // 俱乐部培养
          trained_at_club: ""
        },
        // 位置
        positions: {
          // 门将
          goalkeeper: "0",
          // 清道夫
          sweeper: "0",
          //左后卫
          defender_left: "0",
          // 中后卫
          defender_central: "0",
          // 右后卫
          defender_right: "0",
          // 防守型中场
          defensive_midfielder: "0",
          // 左边卫
          wing_back_left: "0",
          // 右边卫
          wing_back_right: "0",
          // 左前卫
          midfielder_left: "0",
          // 中前卫
          midfielder_central: "0",
          // 右前卫
          midfielder_right: "0",
          // 左路攻击前卫
          attacking_midfielder_left: "0",
          // 中路攻击前卫
          attacking_midfielder_central: "0",
          // 右路攻击前卫
          attacking_midfielder_right: "0",
          // 前锋
          attacker: "0",
          // 主打位置
          preferred_central_position: "0",
        },
        // 精神属性
        mental_attributes: {
          // 侵略性
          aggression: "0",
          // 预判
          anticipation: "0",
          // 勇敢
          bravery: "0",
          // 镇定
          composure: "0",
          // 集中
          concentration: "0",
          // 稳定
          consistency: "0",
          // 决断
          decisions: "0",
          // 意志力
          determination: "0",
          // 坑脏动作
          dirtyness: "0",
          // 想象力
          flair: "0",
          // 大赛发挥
          important_matches: "0",
          // 影响力
          influence: "0",
          // 无球跑动
          off_the_ball: "0",
          // 防守站位
          positioning: "0",
          // 团队合作
          team_work: "0",
          // 视野
          cretivity: "0",
          // 工作投入
          work_rate: "0"
        },
        // 身体属性
        physical_attributes: {
          // 爆发力
          acceleration: "0",
          // 灵活
          agility: "0",
          // 平衡
          balance: "0",
          // 受伤倾向
          injury_proneness: "0",
          // 弹跳
          jumping: "0",
          // 体质
          natural_fitness: "0",
          // 速度
          pace: "0",
          // 耐力
          stamina: "0",
          // 强壮
          strength: "0"
        },
        // 技术属性
        technical_attributes: {
          // 角球
          corners: "0",
          // 传中
          crossing: "0",
          // 盘带
          dribbling: "0",
          // 射门
          finishing: "0",
          // 接球
          first_touch: "0",
          // 任意球
          free_kicks: "0",
          // 头球
          headings: "0",
          // 远射
          long_shots: "0",
          // 界外球
          long_throws: "0",
          // 盯人
          marking: "0",
          // 传球
          passing: "0",
          // 罚点球
          penalty_taking: "0",
          // 抢断
          tackling: "0",
          // 技术
          technique: "0",
          // 多面性
          versatility: "0"
        },
        // 门将属性
        goalkeeping_attributes: {
          // 制空能力
          aerial_ability: "0",
          // 拦截传中
          command_of_area: "0",
          // 指挥防守
          communication: "0",
          // 神经指数
          eccentricity: "0",
          // 手控球
          handing: "0",
          // 大脚开球
          kicking: "0",
          // 一对一
          one_on_ones: "0",
          // 反应
          reflexes: "0",
          // 出击
          rushing_out: "0",
          // 击球倾向
          tendency_to_punch: "0",
          // 手抛球能力
          throwing: "0"
        },
        // 习惯表现数据
        perferred_moves_data: [],
        // 喜欢的人数据
        favourite_people_data:[],
        // 讨厌的人数据
        disliked_people_data: [],
        // 钟爱的俱乐部数据
        favourite_clubs_data: [],
        // 讨厌的俱乐部数据
        disliked_clubs_data: [],
        // 国家队生涯数据
        national_team_career_data: [],
        // 转会记录数据
        transfer_history_data: [],
        // 球员历史数据
        playing_history_data: [],
        // 游戏版本
        game_version: "",
        // 积分
        integral: 0
      }
    }
  },
  methods: {
    jump_page,
    // 上传图片之前
    before_cover_image_upload(file){
      let file_size = file.size / 1024 / 1024 < 2;
      // 判断文件格式
      if(file.name.indexOf(".") === -1){
        error_hint("图片文件格式不对, 请重新选择文件");
        return false;
      }
      // 判断文件大小
      if(!file_size){
        error_hint("图片文件, 请控制在2MB以内");
        return false;
      }
      this.cover_image_data = URL.createObjectURL(file.raw);
      // 上传图片
      image_upload({
        image: file.raw
      }).then(result => {
        if(result.Code === 1){
          this.edit_from.cover_info.image = result.Data.image_path;
          success_hint("图片上传成功");
        }else {
          error_hint("图片上传失败, 请重新选择图片上传");
        }
      });
    },
    before_details_image_upload(file){
      let file_size = file.size / 1024 / 1024 < 2;
      // 判断文件格式
      if(file.name.indexOf(".") === -1){
        error_hint("图片文件格式不对, 请重新选择文件");
        return false;
      }
      // 判断文件大小
      if(!file_size){
        error_hint("图片文件, 请控制在2MB以内");
        return false;
      }
      this.details_image_data = URL.createObjectURL(file.raw);
      // 上传图片
      image_upload({
        image: file.raw
      }).then(result => {
        if(result.Code === 1){
          this.edit_from.details.image = result.Data.image_path;
          success_hint("图片上传成功");
        }else {
          error_hint("图片上传失败, 请重新选择图片上传");
        }
      });
    },
    // 添加球员数据
    post_sportsman_check(){
      // 检查球员图片是否上传
      if(this.edit_from.cover_info.image === "" || this.edit_from.cover_info.image === null){
        warning_hint("请上传球员封面信息中的图片");
        return false;
      }
      if(this.edit_from.cover_info.chinese_name === "" || this.edit_from.cover_info.chinese_name === null){
        warning_hint("请输入球员封面信息中的中文名称");
        return false;
      }
      if(this.edit_from.cover_info.english_name === "" || this.edit_from.cover_info.english_name === null){
        warning_hint("请输入球员封面信息中的英文名称");
        return false;
      }
      if(this.edit_from.cover_info.date_of_birth === "" || this.edit_from.cover_info.date_of_birth === null){
        warning_hint("请输入球员封面信息中的出生日期");
        return false;
      }
      if(this.edit_from.cover_info.field_position === "" || this.edit_from.cover_info.field_position === null){
        warning_hint("请输入球员封面信息中的场上位置");
        return false;
      }
      if(this.edit_from.cover_info.field_position === "" || this.edit_from.cover_info.field_position === null){
        warning_hint("请输入球员封面信息中的场上位置");
        return false;
      }
      if(this.edit_from.cover_info.represent_the_country === "" || this.edit_from.cover_info.represent_the_country === null){
        warning_hint("请输入球员封面信息中的代表国家");
        return false;
      }
      // 检查游戏版本是否输入
      if(this.edit_from.game_version === "" || this.edit_from.game_version === null){
        warning_hint("请输入游戏版本");
        return false;
      }
      // 上传数据
      this.post_sportsman_data();
    },
    post_sportsman_data(){
      add_sportsman({
        cover_info: this.edit_from.cover_info,
        details: this.edit_from.details,
        international_data: this.edit_from.international_data,
        contract: this.edit_from.contract,
        attributes: this.edit_from.attributes,
        general: this.edit_from.general,
        positions: this.edit_from.positions,
        mental_attributes: this.edit_from.mental_attributes,
        physical_attributes: this.edit_from.physical_attributes,
        technical_attributes: this.edit_from.technical_attributes,
        goalkeeping_attributes: this.edit_from.goalkeeping_attributes,
        perferred_moves: this.edit_from.perferred_moves_data,
        favourite_people: this.edit_from.favourite_people_data,
        disliked_people: this.edit_from.disliked_people_data,
        favourite_clubs: this.edit_from.favourite_clubs_data,
        disliked_clubs: this.edit_from.disliked_clubs_data,
        national_team_career: this.edit_from.national_team_career_data,
        transfer_history: this.edit_from.transfer_history_data,
        playing_history: this.edit_from.playing_history_data,
        game_version: this.edit_from.game_version,
        integral: this.edit_from.integral
      }).then(response => {
        if(response.Code === 1){
          success_hint("添加数据成功");
          this.jump_page("/");
        }
      })
    },
    // 习惯数据操作
    add_perferred_moves_data(){
      this.edit_from.perferred_moves_data.push(
          {
            text: ""
          }
      );
    },
    del_perferred_moves_data(index){
      console.log(index);
      this.edit_from.perferred_moves_data.splice(index, 1);
    },
    // 喜欢的人数据操作
    add_favourite_people_data(){
      this.edit_from.favourite_people_data.push(
          {
            personnel: "",
            level: "",
            reason: "",
            continued: ""
          }
      );
    },
    del_favourite_people_data(index){
      this.edit_from.favourite_people_data.splice(index, 1);
    },
    // 讨厌的人数据操作
    add_disliked_people_data(){
      this.edit_from.disliked_people_data.push(
          {
            personnel: "",
            level: "",
            continued: ""
          }
      );
    },
    del_disliked_people_data(index){
      this.edit_from.disliked_people_data.splice(index, 1);
    },
    // 钟爱的俱乐部数据操作
    add_favourite_clubs_data(){
      this.edit_from.favourite_clubs_data.push(
          {
            club: "",
            reason: "",
            level: "",
          }
      );
    },
    del_favourite_clubs_data(index){
      this.edit_from.favourite_clubs_data.splice(index, 1);
    },
    // 讨厌的俱乐部数据操作
    add_disliked_clubs_data(){
      this.edit_from.disliked_clubs_data.push(
          {
            club: "",
            level: ""
          }
      );
    },
    del_disliked_clubs_data(index){
      this.edit_from.disliked_clubs_data.splice(index, 1);
    },
    // 国家队生涯数据操作
    add_national_team_career_data(){
      this.edit_from.national_team_career_data.push(
          {
            match_date: "",
            opponent: "",
            goal: "",
            assist: ""
          }
      );
    },
    del_national_team_career_data(index){
      this.edit_from.national_team_career_data.splice(index, 1);
    },
    // 转会记录数据操作
    add_transfer_history_data(){
      this.edit_from.transfer_history_data.push(
          {
            transfer_date: "",
            transfer_to_the_team: "",
            transfer_fee: ""
          }
      );
    },
    del_transfer_history_data(index){
      this.edit_from.transfer_history_data.splice(index, 1);
    },
    // 球员列表数据操作
    add_playing_history_data(){
      this.edit_from.playing_history_data.push(
          {
            year: "",
            club: "",
            attributes: "",
            debut: "",
            goal: ""
          });
    },
    del_playing_history_data(index){
      this.edit_from.playing_history_data.splice(index, 1);
    },
  },
  components: {
    Header,
    Footer,
    Plus
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed black;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  border: 1px dashed black;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
/* 编辑器 */
.title {

}

.content > .edit_content{
  background: white;
  padding: 15px 20px;
}

.content > .edit_content >>> .title{
  font-weight: bold;
  border-left: 3px solid #34b261;
  color: #34b261;
  padding-left: 9px;
  font-size: 16px;
}

.content > .sportsman_details{
  background: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px;
  display: flex;
  align-items: flex-start;
}

.content > .sportsman_details > .photo{
  width: 300px;
  height: 400px;
  margin-right: 10px;
}

.content > .sportsman_details > .photo > img{
  width: 100%;
  height: 100%;
}

.content > .sportsman_details > .details{
  flex: 1;
  color: #666666;
}


.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px #f4f4f6 dashed;
  padding: 5px 0;
  font-size: 12px;
}

.content > .sportsman_attribute {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
}

.content > .sportsman_attribute > .left{
  flex: 1;
}

.content > .sportsman_attribute > .right{
  width: 348px;
  margin-left: 10px;
}

.attribute_item {
  padding: 25px;
  background: #FFFFFF;
  margin-bottom: 10px;
  color: #666666;
}

.attribute_item > table{
  width: 100%;
  color: #666666;
  border-collapse: collapse;
  border-spacing: "";
  font-size: 12px;
}

.attribute_item > table > thead, .attribute_item > table > thead > tr{
  border-bottom: 1px #f4f4f6 dashed;
}

.attribute_item > table > thead > tr > td{
  padding: 7px 0;
  text-align: left;
  font-weight: bold;
}

.attribute_item > table > tbody > tr > td{
  padding: 5px 0;
}

.attribute_item > .pages {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.el-pagination{
  --el-pagination-hover-color: #34b261;
}

</style>